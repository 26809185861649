import * as Sentry from "@sentry/nuxt";

//const tracing = Sentry.browserTracingIntegration({ router });
const replay = Sentry.replayIntegration({
  maskAllText: false,
  blockAllMedia: false,
});
const feedback = Sentry.feedbackIntegration({
  autoInject: false,
});

Sentry.init({
  dsn: "https://34fcecb8627adb1072df35dfeefdd197@o4507087479635968.ingest.us.sentry.io/4507087485272064",
  tracesSampleRate: 0.01,
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0,
  environment: import.meta.env.NODE_ENV,
  integrations: [replay, feedback], //tracing
  enabled: !import.meta.env.DEV,
});
